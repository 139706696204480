import React from "react"
import styled from "styled-components";
import NameTitle from "./name-title.svg";
import Email from "./email.svg";

const Nav = () => {
	return (
		<CustomNav className="is-flex">
			<div className="is-relative">
				<p className="has-text-weight-bold">Radu Sabo</p>
				<p>Product Designer</p>
				<img
					src={NameTitle}
					alt=""
					className="text-note is-hidden-touch"
					style={{ top: "0px", left: "88px" }}
				/>
			</div>
			<a
				href="mailto:contact@radusabo.com?subject=Hey Radu!"
				target="_blank"
        rel="noreferrer"
				className="is-relative has-text-white is-underlined mb-6"
			>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="is-relative mr-1"
					style={{ top: "5px" }}
				>
					<path
						d="M3.33342 3.33325C2.41258 3.33325 1.66675 4.07909 1.66675 4.99992V14.9999C1.66675 15.9207 2.41258 16.6666 3.33342 16.6666H16.6668C17.5876 16.6666 18.3334 15.9207 18.3334 14.9999V4.99992C18.3334 4.07909 17.5876 3.33325 16.6668 3.33325H3.33342ZM3.33342 4.99992H16.6668V5.83488L10.0001 9.99992L3.33342 5.83488V4.99992ZM3.33342 7.50155L10.0001 11.6666L16.6668 7.50155V14.9999H3.33342V7.50155Z"
						fill="white"
					/>
				</svg>
				contact@radusabo.com
				<img
					src={Email}
					alt=""
					className="text-note"
					style={{ top: "40px", left: "16px" }}
				/>
			</a>
		</CustomNav>
	);
};

export default Nav;


const CustomNav = styled.nav`
	justify-content: space-between;
	@media (max-width: 769px) {
		flex-direction: column;
	}
`;