import * as React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import Nav from "../components/nav";
import Do from "../components/do.svg";
import Stuff from "../components/stuff.svg";
import "../style/app.scss";
import Favicon from "../components/rs.png";

// markup
const IndexPage = () => {
	return (
		<Main>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Radu Sabo - Product Designer</title>
				<link rel="canonical" href="https://radusabo.com" />
        <link rel="icon" href={Favicon} />
				<meta
					name="description"
					content="Radu Sabo is a multidisciplinary product designer with 5+ years of experience working with startups, during which he helped raise over $2 million in funding."
				/>

				<meta content="Radu Sabo - Product Designer" property="og:title"></meta>
				<meta
					content="Radu Sabo is a multidisciplinary product designer with 5+ years of experience working with startups, during which he helped raise over $2 million in funding."
					property="og:description"
				></meta>

				<meta
					content="Radu Sabo - Product Designer"
					property="twitter:title"
				></meta>
				<meta
					content="Radu Sabo is a multidisciplinary product designer with 5+ years of experience working with startups, during which he helped raise over $2 million in funding."
					property="twitter:description"
				></meta>
				<meta property="og:type" content="website"></meta>
				{/* <meta name="twitter:card" content="summary_large_image"></meta> */}
			</Helmet>
			<div className="main-inside">
				<div className="container">
					<section className="section">
						<Nav />
					</section>

					<section className="section">
						<div className="columns">
							<div className="column is-6">
								<div className="is-relative">
									<p className="mb-2">👋 Hello!</p>
									<p className="has-text-weight-bold is-size-3 is-size-4-touch">
										I help smart founders design and build successful products,
										so they can focus on fundraising or growth.
									</p>
									<img
										src={Do}
										alt=""
										className="text-note "
										style={{ top: "-40px", right: "40px" }}
									/>
								</div>
								{/* /intro */}
								<div
									className="content mt-6 is-relative"
									style={{ marginBottom: "7rem" }}
								>
									<p>
										Tolling and congestion solutions @{" "}
										<a
											href="https://clearroad.io/"
											target="_blank"
											rel="noreferrer"
											className="is-underlined clearroad"
										>
											ClearRoad
										</a>
									</p>
									<p>
										Better and more efficient personal development @{" "}
										<a
											href="https://growkarma.io/"
											target="_blank"
											rel="noreferrer"
											className="is-underlined growkarma"
										>
											GrowKarma
										</a>
									</p>
									<p>
										Simplified pet licensing @{" "}
										<a
											href="https://roamingtails.com/"
											target="_blank"
											rel="noreferrer"
											className="is-underlined rt"
										>
											RoamingTails
										</a>
									</p>
									<img
										src={Stuff}
										alt=""
										className="text-note"
										style={{ bottom: "-100px", left: "140px" }}
									/>
								</div>
							</div>
						</div>
					</section>
				</div>

				<Rank>
					<div className="rank-holder">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</Rank>
			</div>
		</Main>
	);
};

export default IndexPage;

const Main = styled.div`
	min-height: 100vh;
	height: 100%;
	color: #fff;
	padding: 0.75rem;
	@media (max-width: 768px) {
		padding: 0 !important;
	}
	.main-inside {
		position: relative;
		display: flex;
		flex-direction: column;
		background: linear-gradient(294.13deg, #313131 17.97%, #111111 90.95%);
		height: 100%;
		min-height: calc(100vh - 1.5rem);
		width: 100%;
	}
`;

const Rank = styled.div`
	background: #fff;
	// background: blue;
	height: 8px;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	.rank-holder {
		background: #313131;
		width: 112px;
		height: 100%;
		margin-right: 80px;
		display: flex;
		span {
			background: #fff;
			width: 8px;
			height: 100%;
			display: inline-block;
			margin-left: 16px;
		}
	}
`;
